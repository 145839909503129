import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {NavItem} from '../../models/nav-item';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input()
  _rulesExportFeature: boolean[];

  get rulesExportFeature(): boolean[] {
    return this._rulesExportFeature;
  }

  @Input() set rulesExportFeature(value: boolean[]) {
    this._rulesExportFeature = value;
    const isRuleExportAvailable = this._rulesExportFeature[0];
    const isSendAndFileExportAvailable = this._rulesExportFeature[1] && !this.authService.isMicrosoftProvider();
    this.menu = [
      {
        name: 'Rules',
        route: '/dashboard/rules',
        show: isRuleExportAvailable
      },
      {
        name: 'Reports',
        show: true,
        children: [
          {
            name: 'Single exports',
            route: '/dashboard/report/single',
            show: true
          },
          {
            name: 'Batch exports',
            route: '/dashboard/report/rule',
            show: isRuleExportAvailable
          },
          {
            name: 'Send & file',
            route: '/dashboard/report/send-and-file',
            show: isSendAndFileExportAvailable
          }
        ]
      },
      {
        name: 'Personal settings',
        route: '/dashboard/personal-settings',
        show: true
      }
    ];
    this.cdr.detectChanges();
  }

  menu: NavItem [] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly authService: AuthService
  ) {
  }
}
