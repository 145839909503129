import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-report-detail-column',
  templateUrl: './details-column.component.html',
  styleUrls: ['./details-column.component.scss'],
})
export class DetailsColumnComponent implements OnInit {

  @Input() messageExportJob: any;
  @Input() isRuleExportDetail = false;
  message: string;

  ngOnInit(): void {
    const exportMessage = this.messageExportJob.exportMessage;
    if (exportMessage?.startsWith('[RATE_LIMIT_EXCEEDED]')) {
      this.message = 'Rate limit exceeded. Please try again later.';
    } else if (exportMessage?.startsWith('[TOO_MANY_REQUEST]')) {
      this.message = 'Too many requests at the same time. Please try again later.';
    } else if (exportMessage?.startsWith('[DATABASE_ACCESS_ERROR]')) {
      this.message = 'An error occurred while accessing the database. Please try again later.';
    } else if (exportMessage?.startsWith('[NOT_ENOUGH_TIME]')) {
      this.message = 'The export took too long to complete. Please try again.';
    } else {
      this.message = exportMessage;
    }
  }
}
