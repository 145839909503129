<h2 mat-dialog-title>
  <span>{{ rule ? 'Edit rule : ' + rule.name : 'Create a new rule' }}</span>
</h2>

<button data-type="button-dialog-close" mat-mini-fab color="primary" class="close-button" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content id="rule-creator-editor-content">
  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <form [formGroup]="ruleForm"  [hidden]="loading$ | async">
    <div class="aoRow aoRow--fill aoRow--mGap">
      <mat-form-field appearance="fill" class="aoSmall">
        <mat-label>Rule name</mat-label>
        <input data-type="input-rule-name" id="input-rule-name" matInput formControlName="name" autocomplete="off">
      </mat-form-field>
      <mat-form-field appearance="fill" class="aoFill">
        <mat-label>Description</mat-label>
        <input data-type="input-description-name" matInput formControlName="description" autocomplete="off">
      </mat-form-field>
    </div>
    <div *ngIf="isGoogleProvider" class="aoRow">
      <mat-form-field appearance="fill" class="aoFill">
        <mat-label>Gmail labels</mat-label>
        <mat-select data-type="input-label-select"  formControlName="labels" multiple required>
          <mat-optgroup *ngFor="let group of sortedLabels" [label]="group.type" data-type="label-group">
            <mat-option *ngFor="let label of group.list" [value]="label" data-type="label-option" [attr.data-label]="label.name">
              {{label.name}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <div  *ngIf="authProvider === authProviderEnum.MICROSOFT" class="folder-form">
      <span>Select the Outlook folder containing the emails you want to save</span>
      <cdk-accordion>
        <cdk-accordion-item #accordionItem="cdkAccordionItem">
          <mat-form-field appearance="fill" data-type="button-expand-folder-accordion"
                          (click)="accordionItem.toggle();$event.stopPropagation();$event.preventDefault();">
            <mat-label>Outlook folders</mat-label>

            <mat-chip-grid #chipGrid data-type="chiplist-folders">
              <mat-chip-row *ngFor="let folder of ruleForm.get('labels').value"
                            (removed)="removeFolder(folder)"
                            [attr.aria-label]="folder.name"
                            data-type="chip-folder"
              >
                {{folder.name}}

                <button matChipRemove [attr.aria-label]="folder.name">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>

            <input formControlName="labels" [hidden]="true" [matChipInputFor]="chipGrid" />

            <button matSuffix mat-icon-button>
              <mat-icon>{{ accordionItem.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
            </button>
          </mat-form-field>

          <app-folder-tree [style.display]="accordionItem.expanded ? '' : 'none'"
                           role="region"
                           [selected]="ruleForm.get('labels').value"
                           [includeSubFolder]="ruleForm.get('includeSubFolder').value"
                           (selectedChange)="handleFolderSelection($event)"></app-folder-tree>
        </cdk-accordion-item>
      </cdk-accordion>
      <mat-checkbox data-type="button-checkbox-include-subfolder" formControlName="includeSubFolder" matTooltip="This can only be set during rule creation">Export emails in subfolders too</mat-checkbox>
    </div>
    <div *ngIf="authProvider === authProviderEnum.MICROSOFT" class="folder-form">
      <span>Define the destination in AODocs</span>
    </div>

    <div class="aoRow aoRow--mGap">
      <mat-form-field appearance="fill" class="aoFill">
        <mat-label>Library</mat-label>
        <input type="text"
               placeholder="Select a value"
               aria-label="Library"
               matInput
               required
               data-type="input-library"
               formControlName="library"
               (keydown)="checkLibraryState($event)"
               [readOnly]="libraryReadOnly"
               [matAutocomplete]="autoLibrary">
        <mat-autocomplete #autoLibrary="matAutocomplete" (optionSelected)="loadDocumentClasses($event.option.value, true)"
                          [displayWith]="displayByName">
          <mat-option *ngFor="let library of libraries" [value]="library"
                      data-type="option-library"
                      [attr.data-label]="library.name">
            {{library.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field *ngIf="showDocumentClassField" appearance="fill" class="aoFill">
        <mat-label>Document class</mat-label>
        <input type="text"
               placeholder="Select a value"
               aria-label="DocumentClass"
               matInput
               required
               data-type="input-class"
               formControlName="documentClass"
               [matAutocomplete]="autoDocumentClass">
        <mat-autocomplete #autoDocumentClass="matAutocomplete" (optionSelected)="fetchCategories(null, $event.option.value)"
                          [displayWith]="displayByName">
          <mat-option *ngFor="let documentClass of documentClasses" [value]="documentClass"
                      data-type="option-class"
                      [attr.data-label]="documentClass.name">
            {{documentClass.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <ng-container *ngIf="documentClassSelected">
      <div class="aoRow aoRow--mGap">
        <mat-form-field appearance="fill" class="aoFill" *ngIf="isCategories">
          <mat-label>Category</mat-label>
          <mat-select formControlName="category" #categorySelect
                      data-type="input-category-select"
                      (selectionChange)="fetchCategoryValues(categorySelect.value)">
            <mat-option></mat-option>
            <mat-option *ngFor="let category of categories" [value]="category"
                        data-type="option-category"
                        [attr.data-label]="category.displayName">{{category.displayName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-label *ngIf="isCategories === false" class="aoFill">The selected class has no categories
        </mat-label>
        <mat-form-field appearance="fill" class="aoFill" *ngIf="isCategories">
          <mat-label>Category value</mat-label>
          <input type="text"
                 placeholder="Select a value"
                 aria-label="Category value"
                 matInput
                 data-type="input-category-value-select"
                 formControlName="categoryValue"
                 [matAutocomplete]="autoCategoryValue"
                 (keydown)="isCategoryValueBackspace($event)"
                 #autoTrigger="matAutocompleteTrigger">
          <mat-autocomplete #autoCategoryValue="matAutocomplete" [displayWith]="displayByNameCategoryValue"
                            class="category-value-autocomplete">
            <ng-container *ngFor="let value of filteredOptions; let last = last">
              <mat-option [value]="value" (click)="open($event)"
                          data-type="option-category-value"
                          [attr.data-label]="categoryValuePath + value.name">
                {{categoryValuePath}}{{value.name}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div>
        <mat-slide-toggle formControlName="frequency" data-type="toggle-frequency" aria-label="Run once per week" color="primary">
          Run once per week
        </mat-slide-toggle>
      </div>

    </ng-container>

    <section mat-dialog-actions align="end">
      <section>
        <button data-type="button-save-rule" class="fw-500"
                mat-raised-button
                color="primary"
                [disabled]="!ruleForm.valid || (loading$ | async)"
                (click)="saveOrUpdate()"
        > Save </button>
      </section>
    </section>
  </form>
</div>

